<template>
  <div>
    <el-carousel height="520px" :interval="2500" >
      <el-carousel-item v-for="(i,k) in datalist" :key="k">
        <!-- <img style="width:100%;height:100%;min-width:1920px" :src="i.url" alt="banner"> -->
        <el-image style="width:100%;height:100%" :src="i.url" fit="cover"></el-image>
        <!-- <div class="index-banner" style="width:100%;height:100%;background:url('i.url');"></div> -->
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name: 'swiper',
  props:['datalist'],
  data() {
    return {

    }
  },
  create() { },
  wacth: {},
  methods: {},
  computed: {},
}
</script>
<style lang="scss" scoped>
</style>
